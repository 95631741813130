.footer {
    width: 100%;
    height: fit-content;
    background-color: black;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 2rem;
    box-sizing: border-box;
}

.footerMap {
    width: 25rem;
    height: 25rem;
    margin: 1rem;
}

.mapCaption {
    color: white;
}

.logoContainer {
    text-align: center;
}

.logo {
    width: 70%;
}

.companyNameParagraph {
    color: white;
}

.companyNameLink {
    color: white;
}

.hoursContainer {
    color: white;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .footer {
        grid-template-columns: repeat(1, 1fr);
    }

    .mapContainer {
        grid-row: 3 / 4;
    }

    .footerMap {
        width: 80%;
        height: 15rem;
    }

    .logoContainer {
        grid-row: 1 / 2;
    }

    .hoursContainer {
        grid-row: 2 / 3;
    }
}

@media screen and (max-width: 50rem) {
    
}