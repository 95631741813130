.listItem {
    display: block;
    border: 1px solid black;
    margin: 1rem;
    padding: 0.5rem;
}

.editButton {
    border: 1px solid black;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.editButton:hover {
    cursor: pointer;
}