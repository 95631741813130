.contactUsPage {
    background-color: white;
    
    padding: 10rem 0 0 0;
}

.contactUsTitle {
    margin: 1rem auto;
}

.contactUsDescription {
    width: 95%;
    max-width: 55rem;
    margin: auto;
}

.formContainer {
    width: 95%;
    max-width: 40rem;
    margin: 2rem auto;
    background-color: rgb(189, 189, 189);
}