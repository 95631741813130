.tiresPage {
    background-color: rgb(0, 0, 0);
    padding: 10rem 0 1rem 0;

    border-bottom: 3px solid white;
}

.tiresTitle {
    color: white;
    margin: 1rem auto;
}

.tireTypesContainer {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.tireTypeItem {
    margin: auto;
}

.tireImageContainer {
    width: 31vw;
    height: 37vw;
    margin: auto;
    border-radius: 1rem;

    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
}

.tireImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: center; /* Centers the image within the container */
}

.tireTypeTitle {
    color: white;
    text-align: left;
    margin: 1rem 0;
}

.tiresDescriptionContainer {
    width: 100%;
    margin-top: 2rem;
    padding: 1rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;
}

.tiresDescriptionLeft {
    grid-column: 1 / 7;
}

.tiresDescriptionParagraph {
    color: white;
    text-align: left;
    width: 100%;
    display: inline-block;
    margin: 0;
}

.tiresDescriptionRight {
    grid-column: 7 / 11;    
}

.tiresButton {
    margin: 2rem auto;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .tireTypesContainer {
        grid-template-columns: repeat(1, 1fr);
        gap: 5rem;
    }

    .tireTypeItem {
        width: 100%;
    }

    .tireImageContainer {
        width: 100%;
        height: 35rem;
    }

    .tiresDescriptionContainer {
        grid-template-columns: repeat(1, 1fr);
    }
    
    .tiresDescriptionLeft {
        grid-column: 1 / 2;
    }

    .tiresDescriptionRight {
        grid-column: 1 / 2;
    }

    .tiresButton {
        margin: 0rem auto;
    }
}