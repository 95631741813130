.navbarDesktop {
    position: fixed;
    height: 9em;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #000000;
    z-index: 20;
}

.navbarDesktopContent {
    position: relative;
    height: 100%;
    width: 100%;
}

.optionsContainer {
    width: 35%;
    min-width: 28rem;
    position: absolute;
    text-align: left;
}

.navLink {
    color: rgb(248, 221, 110);
    font-size: 1.2rem;
    display: inline-block;
    margin: 1rem;
    text-decoration: none;
}

.logo {
    height: 100%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.navbarButtonContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translate(0, -50%);
}