.careersPage {
    background-color: rgb(248, 221, 110);
    padding: 12rem 2rem 2rem 2rem;
    /* box-sizing: border-box; */
}

.careersPageContentContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    margin: auto;
}

.careersPageLeftContainer {

}

.imageContainer {
    width: 90%;
    height: 60vw;
    margin: auto;
    border-radius: 1000rem 1000rem 0 0;

    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: center; /* Centers the image within the container */
}

.careersPageRightContainer {

}

.rightSectionTitle {
    text-align: left;
    margin: 1rem auto;
}

.imageContainerMobile {
    display: none;
    width: 90%;
    height: 70vw;
    margin: auto;
    border-radius: 10px;

    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
}

.imageContainerMobile img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: 0 35%; /* Centers the image within the container */
}

.formContainer {
    width: 100%;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .careersPage {
        background-color: rgb(248, 221, 110);
        padding: 12rem 0rem 2rem 0rem;
        /* box-sizing: border-box; */
    }

    .careersPageContentContainer {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }

    .imageContainerMobile {
        display: block;
    }

    .careersPageLeftContainer {
        display: none;
    }
}

@media screen and (max-width: 50rem) {
   
    
}