.partsPage {

}

.partsBanner {
    width: 100%;
    height: 48rem;
    position: relative;
    background-image: url('../assets/gears.jpg');
    background-size: cover;
}

.partsBannerTitle {
    color: rgb(248, 221, 110);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 100%;
    max-width: 50rem;
}

.custom_shape_divider_bottom_1726861097_desk {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom_shape_divider_bottom_1726861097_desk svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom_shape_divider_bottom_1726861097_desk .shape_fill_desk {
    fill: #D2D2D2;
}

.partsPageContent {
    background-color: #D2D2D2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem;
    gap: 1rem;
}

.partsContentLeft {
    text-align: left;
    padding: 1rem;
}

.partsCircularImageContainer {
    width: 20rem;
    height: 20rem;
    margin: 2rem auto;
    content: '';
    background-color: aliceblue;
    border-radius: 50%;

    /* Child image adjustment */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.partsCircularImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    position: absolute;
    top: 0;
    left: 0;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .partsBanner {
        height: 40rem;
    }

    .partsPageContent {
        gap: 0;
    }

    .custom_shape_divider_bottom_1726861097_desk svg {
        height: 65px;
    }

    .partsPageContent {
        grid-template-columns: repeat(1, 1fr);
    }
}