.floatingCallIcon {
    width: 5rem;
    height: 5rem;
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 100;
    transition: all 0.1s linear;
}

.floatingCallIcon:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.floatingCallIcon img {
    width: 100%;
}