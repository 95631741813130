.homePage {

}

.banner {
    width: 100%;
    min-height: 25rem;
    background-image: url('../assets/truck.jpg');
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
}

.bannerContentContainer {
    color: rgb(248, 221, 110);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}

.bannerTitlePrimary {
    font-size: 27vw;
    font-weight: 600;
    width: max-content;
    margin: 0 auto;
}

.bannerTitleSecondary {
    font-size: 3vw;
    font-weight: 600;
    width: max-content;
    margin: -4vw auto 0 auto;
}

.bannerButton {
    margin: 1rem;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .bannerTitlePrimary {
        font-size: 45vw;
    }
    
    .bannerTitleSecondary {
        font-size: 5vw;
    }
}

/* ######################################################## */
/* ################### SERVICES SECTION ################### */
/* ######################################################## */
.servicesSection {
    background-color: rgb(52, 74, 82);
    width: 100%;
    height: fit-content;
    padding: 1rem 1rem 2rem 1rem;
    box-sizing: border-box;
}

.servicesTitle {
    color: white;
    margin: 0 auto;
}

.servicesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin: 2rem 0;
}

.serviceTypeCard {
    width: auto;
    height: 25vw;
    border: 1px solid white;
    border-radius: 3px;
    color: white;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.serviceOneCard {
    background-image: url('../assets/oil_change.jpg');
}

.serviceTwoCard {
    background-image: url('../assets/tires.jpg');
}

.serviceThreeCard {
    background-image: url('../assets/repair.jpg');
    background-position: 50% 45%;
}

.serviceFourCard {
    background-image: url('../assets/engine.jpeg');
}

.servicesCallToAction {
    text-decoration: none;
    color: white;
    font-size: 3rem;
    transition: all 0.1s linear;
}

.servicesCallToAction:hover {
    font-weight: 600;
    color: rgb(248, 221, 110);
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .servicesCallToAction {
        font-size: 2rem;
    }
}

@media screen and (max-width: 50rem) {
    .servicesContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .serviceTypeCard {
        height: 35vw;
    }
}

/* ######################################################### */
/* ################### INVENTORY SECTION ################### */
/* ######################################################### */
.inventorySection{
    background-color: rgb(248, 221, 110);
    padding: 2rem;
}

.newInventoryTitle {
    margin: 0 auto;
    text-align: left;
}

.ctaContainer {
    width: 100%;
    text-align: right;
}

.inventoryCallToAction {
    color: black;
    text-decoration: none;
    font-size: 3rem;
    transition: all 0.1s linear;
}

.inventoryCallToAction:hover {
    font-weight: 600;
    letter-spacing: -0.8px; 
    color: rgb(52, 74, 82);
}

.newInventoryContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin: 1rem 0;
}

.inventoryItem {
    content: '';
    border: 2px solid black;
    border-radius: 3px;
    height: 35vw;
    background-size: cover;
}

.inventoryItemOne {
    background-image: url('../assets//inventory_section/blue_truck.jpg');
}

.inventoryItemTwo {
    background-image: url('../assets//inventory_section/white_truck.jpg');
}

.inventoryItemThree {
    background-image: url('../assets//inventory_section/truck_lot.jpeg');
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .newInventoryContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .inventoryItem {
        height: 110vw;
    }

    .inventoryCallToAction {
        font-size: 2rem;
    }
}

/* ######################################################### */
/* ################### NEW PARTS SECTION ################### */
/* ######################################################### */

.newPartsSection {
    width: 100%;
    height: fit-content;
    background-color: black;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.newPartsSectionImage {
    content: '';
    background-image: url('../assets/part_one.jpg');
    background-size: cover;
    background-position: 50% 50%;
}

.newPartsSectionContent {
    padding: 2rem;
}

.partsTitle {
    color: rgb(248, 221, 110);
    margin: 0 auto;
}

.partsParagraph {
    color: white;
    width: 70%;
    margin: 2rem auto;
}

.partsCircularImageContainer {
    width: 15rem;
    height: 15rem;
    margin: 2rem auto;
    content: '';
    background-color: aliceblue;
    border-radius: 50%;

    /* Child image adjustment */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.partsCircularImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    position: absolute;
    top: 0;
    left: 0;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .newPartsSection {
        grid-template-columns: repeat(1, 1fr);
    }

    .newPartsSectionImage {
        height: 73vw;
    }

    .partsParagraph {
        width: 100%;
    }
}

/* ######################################################## */
/* ################### ABOUT US SECTION ################### */
/* ######################################################## */

.aboutUsSection {
    width: 100%;
    height: fit-content;
    background-color: aliceblue;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.aboutUsSectionContent {
    padding: 2rem 0;
}

.aboutUsTitle {
    text-align: left;
    margin: 1rem auto;
}

.aboutUsParagraph {
    text-align: left;
    margin: 1rem;
}

.aboutUsSectionImage {
    content: '';
    background-image: url('../assets/two_men_repairing.jpeg');
    background-size: cover;
    background-position: 50% 50%;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .aboutUsSection {
        grid-template-columns: repeat(1, 1fr);
    }

    .aboutUsSectionImage {
        height: 25rem;
    }

    .aboutUsSectionContent {
        padding: 1rem 0;
    }
}