.adminDashboard {
    width: 100%;
    height: fit-content;
    padding-top: 10rem;
}

.pageTitle {
    margin: 1rem auto;
}

.itemCreationSection {
    text-align: left;
    padding: 0 1rem;
}

.uploadedImage {
    width: 15rem;
    display: block;
}

.manageInventorySection {
    text-align: left;
    padding: 0 1rem;
}