.smallButton {
    color: white;
    min-width: fit-content;
    padding: 5px 5px;
    border-radius: 5px;
    display: inline-block;
    border: 2px solid white;
    background-color: black;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.2s ease-out;
}

.smallButton::before {
    content: "";
    position: absolute;
    background: rgb(248, 221, 110);
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: all 0.2s ease-out;
}

.smallButton:hover {
    color: black;
    border: 2px solid black;
    cursor: pointer;
    transform: translateY(0.0rem);
}

.smallButton:hover::before {
    top: 0;
}

.smallButton p {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 0.7rem;
}