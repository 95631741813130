.form {
    width: 100%;
    text-align: left;
    padding: 1.5rem;
    box-sizing: border-box;
    font-size: 1.5rem;
}

.nameContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.fullSizeInput {
    width: 100%;
    height: 2rem;
    padding: 0 0.5rem;
    box-sizing: border-box;
    border: none;
    border-radius: 100000rem;
    height: 2.5rem;
    font-size: 1.5rem;
}

.inputSection {
    display: block;
    margin: 1rem 0;
    box-sizing: border-box;
    width: 100%;
}

.messageTextArea {
    width: 100%;
    height: 7rem;
    padding: 0 0.5rem;
    box-sizing: border-box;
    resize: none;
    border-radius: 1rem;
    border: none;
    font-size: 1.5rem;
}

.sendButtonContainer {
    width: 100%;
    text-align: center;
}

.sendButton {
    background-color: black;
    color: white;
    width: fit-content;
    padding: 0.5rem 2rem;
    font-size: 2rem;
    border-radius: 0.5rem;
}

.sendButton:hover {
    cursor: pointer;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .nameContainer {
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }
}