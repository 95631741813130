.desktopNavbar {
    display: block;
}

.mobileNavbar {
    display: none;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .desktopNavbar {
        display: none;
    }

    .mobileNavbar {
        display: block;
    }
}

@media screen and (max-width: 50rem) {
    
}