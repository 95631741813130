.navbarMobile {
    height: fit-content;
    width: 100vw;
    position: fixed;
    padding: 0.5rem;
    box-sizing: border-box;
    background-color: black;
    z-index: 20;
}

.navbarMobileContent {
    width: 100%;
    height: 8rem;
    position: relative;
}

.logo {
    height: 100%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.navBarIcon {
    height: 3rem;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY( -50%);
}

.closeIcon {
    display: none;
}

.navBarIcon:hover {
    cursor: pointer;
}

.optionContainer {
    width: 100%;
    height: fit-content;
    margin-top: 1rem;
    display: none;
}

.navLink {
    color: rgb(248, 221, 110);
    text-decoration: none;
    font-size: 1.7rem;
    /* font-weight: 500; */
    display: block;
    margin: 1rem;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .logo {
        height: 80%;
        top: 50%;
        left: 1rem;
        position: absolute;
        transform: translate(0, -50%);
    }
}