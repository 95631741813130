.aboutUsPage {
    padding: 10rem 2rem 2rem 2rem;
}

.aboutUsPageContentContainer {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.aboutUsTitle {
    margin: 0 auto;
}

.aboutUsLeftContentContainer {
    grid-column: 1 / 7;
    text-align: left;
}

.aboutUsRightContentContainer {
    grid-column: 7 / 11;
    position: relative;
    content: '';
    min-height: 5rem;
}

.buttonContainer {
    width: max-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.aboutUsPageImagesContainer {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;
    margin: 1rem 0;
}

.leftImageContainer {
    grid-column: 1 / 6;
    border-radius: 7px;
    width: 45vw;
    margin: auto;

    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
} 

.leftImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: center; /* Centers the image within the container */
}

.rightImageContainer {
    grid-column: 6 / 11;
    border-radius: 7px;
    width: 45vw;
    margin: auto;

    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
}

.rightImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: center; /* Centers the image within the container */
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .aboutUsPageContentContainer {
        grid-template-columns: repeat(1, 1fr);
    }
    
    .aboutUsLeftContentContainer {
        grid-column: 1 / 2;
    }
    
    .aboutUsRightContentContainer {
        grid-column: 1 / 2;
    } 


    .aboutUsPageImagesContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .leftImageContainer {
        grid-column: 1 / 2;
        width: 100%;
    } 

    .rightImageContainer {
        grid-column: 1 / 2;
        width: 100%;
    } 
}