.inventoryItem {
    width: 20rem;
    height: 28rem;
    /* margin: auto; */
    /* box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); */

    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    transform: translateZ(0);
}

.inventoryItemInner {
    position: relative;
    width: 100%;
    height: 100%;

    transition: transform 0.8s ease;
    transform-style: preserve-3d;
}

/* Do a horizontal flip when you move the mouse over the flip box container */
/* .inventoryItem:hover .inventoryItemInner {
    transform: rotateY(180deg) translateZ(0);
} */

.front, .back { 
    position: absolute;
    width: 100%;
    height: 100%;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.front {
    border: 2px solid black;
    border-radius: 5px;
    background-color: rgb(218, 218, 218);
    color: black;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 0.7rem;
    box-sizing: border-box;
}

.back {
    border: 2px solid black;
    border-radius: 5px;
    background-color: rgb(218, 218, 218);
    color: black;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 0.7rem;
    box-sizing: border-box;

    transform: rotateY(180deg);
}

.imageContainer {
    width: 100%;
    height: 50%;
    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: center; /* Centers the image within the container */
}

.title {
    font-size: 1.7rem;
    margin: 0.5rem 0;
    font-weight: 600;
}

.model {
    font-size: 1.4rem;
    margin: 0.5rem 0;
}

.price {
    font-size: 1.6rem;
    margin: 0.5rem 0;
}

.description {
    font-size: 1rem;
    margin: 0.5rem 0;
}