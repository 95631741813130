.headingOne {
    font-size: 4rem;
    position: relative;
    width: fit-content;

    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease; /* Transition effects */
    transform: translateY(30px); /* Slightly moved down */
}

.visible {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Move back to original position */
}

.underline {
    content: "";
    position: absolute;
    /* left: 7%; */
    bottom: 5px;
    width: 100%;
    height: 14px;
    transform: skew(-40deg);
    background: rgba(255, 208, 19, 0.6);
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    
}

@media screen and (max-width: 50rem) {
    .headingOne {
        font-size: 3rem;
    }
}
