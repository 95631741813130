.inventoryPage {
    padding: 10rem 1rem 4rem 1rem;
}

.inventoryTitle {
    margin: 1rem auto;
}

.introParagraph {
    width: 100%;
    max-width: 65rem;
    margin: 3rem auto;
}

.inventoryItemsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    width: fit-content;
    gap: 3rem;
    margin: auto;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .inventoryItemsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 50rem) {
    .inventoryItemsContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}