.servicesPage {
    padding: 10rem 0 0 0;
    background-color: rgb(52, 74, 82);
}

.servicesPageTitle {
    color: rgb(248, 221, 110);
    margin: 1rem auto;
}

.servicesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin: 0 1rem;
}

.serviceItem {
    text-align: left;
}

.serviceItemTitle {
    color: rgb(248, 221, 110);
}

.serviceItemDescription {
    color: white;
}

.newTiresLink {
    text-decoration: none;
    color: rgb(248, 221, 110);
    font-weight: 600;
    text-decoration: underline;
}

.serviceItemCTAButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* --- Media Queries --- */
@media screen and (max-width: 70rem) {
    .servicesContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 50rem) {
    .servicesContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .servicesCTAButton {
        margin: 2rem 0;
    }
}